/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
import "core-js/stable";
import "regenerator-runtime/runtime";
import "core-js/stable";
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import '../stylesheets/application.scss';
import $ from 'jquery';
import 'select2';
import '../src/search';
import 'jquery-validation';
import 'select2/dist/css/select2.css';
import '../src/jquery.sticky.js';
import Cookies from '../src/cookie.js';

Rails.start();
Turbolinks.start();


$(document).on('turbolinks:load', function() {
  $('.attachment-select').select2({
    minimumResultsForSearch: -1
  });

  $("#header-wrapper").sticky({topSpacing:0, zIndex: 9});
  
  $("#sidebar-wrapper").sticky({topSpacing:145, bottomSpacing: 145});

  $("form[id^=document-modal]").each((index,item) => { 
    $(item).validate({
      errorClass: "c-error"
    });
  });

  $('.c-category-header select').on('change', function(){
    this.form.submit();
  });

  $('.c-header__menu-toggle-input').on('change', function(){
    if($(".c-header__menu-toggle-input").is(':checked') === true) {
      $('body').css('overflow', 'hidden');
      $('.c-header h2 span').css('display', 'none');
    } else {
      $('body').css('overflow', 'initial');
      $('.c-header h2 span').css('display', 'inline-block');
    }
  });

  //$('.open-share-link').on('click', function(){
  //  let documentId = $(this).parents('.c-document-item').data('document-id');
  //  $('#share-link-' + documentId).show();
  //});

  $('.open-share-email').on('click', function(){
    let documentId = $(this).parents('.c-document-item').data('document-id');
    $('#share-email-' + documentId).show();
  });

  $('.open-pdf').on('click', function(){
    let document = $(this).parents('.c-document-item').find('.attachment-select').select2('data')[0];
    if(document) {
      window.open(document.id, '_blank');
    }
  });

  $('.copy-url').on('click', function(){
    let documentId = $(this).parents('.c-document-item').data('document-id');
    let copyText = document.getElementById(`share-document-${documentId}`);
    copyStringToClipboard(copyText.innerText);
    $(this).parents('.c-document-item').find('.c-document-item__share__copied').show();

    setTimeout(function(){
      $('.c-document-item__share__copied').hide();
    }, 3000);
  });

  $(document).on('click', '.c-modal-close', function(){
    $('.c-modal').hide();
    $('.c-document-item__share__copied').hide();
  });

  if(!Cookies.get('cookieAcknowledged') || Cookies.get('cookieAcknowledged') == undefined){
    setTimeout(function(){ 
      $('.c-cookie').show();
    }, 3000);
  }

  const $menu = $('.c-search-menu, .c-header__search');
  $(document).mouseup(e => {
    console.log(e.target);
    if (!$menu.is(e.target) && $menu.has(e.target).length === 0) {
      $('.c-header__search').val('');
      $('.c-header__search__results').css('display', 'none');
      $('.c-header__search__all-results-button').hide();
    }
    });

  $('.c-cookie__content__close').click(function(){
    Cookies.set('cookieAcknowledged', true);
    $('.c-cookie').hide();
  });

  $('.c-category-filter .u-faux-checkbox').on('change', function(){
    $('.loader').show();
    $('#document-content').html('');
    Turbolinks.visit(this.form.action+(this.form.action.indexOf('?') == -1 ? '?' : '&')+$(this.form).serialize());
  });

});

function copyStringToClipboard (str) {
  // Create new element
  var el = document.createElement('textarea');
  // Set value (string to be copied)
  el.value = str;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute('readonly', '');
  el.style = {position: 'absolute', left: '-9999px'};
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand('copy');
  // Remove temporary element
  document.body.removeChild(el);
}