$(document).on('turbolinks:load', function() {
  const searchField = $('.c-header__search');
  const productResults = $('#search-results');

  function makeSearchCall(params) {
    const q = searchField.val();
    params = params || {};
    let queryParam = q;

    if (q.length >= 2) {
      let url = `?search[q]=${q}`;

      window.ajax = $.ajax(`/documents.json${url}`, {
        method: 'GET',
        beforeSend: function() {
          $('.c-header__search__results').css('display', 'flex');
          productResults.html("<div id='search-results'></div><span class='c-header__search__no-result link'>Keresés..</span>");
          $('.c-header__search__all-results-button').hide();
        },
        success: responseHandler(url, queryParam)
      });
    }
  }

  function documentsAssembler(items, queryParam) {
    return items.map(it => {
      if(it.document_url){
        return `<a href="${it.document_url}" class="c-header__search__results__product px-6 py-4">
          <div class="c-header__search__results__product__title">
            ${it.title}
          </div>
        </a>`;
      }
    }).join('');
  }

  function responseHandler(url, queryParam) {
    return function(response) {
      if (response && response.documents.length) {
        //resultsToggle.prop('checked', true);
        productResults.html(documentsAssembler(response.documents.slice(0, 6), queryParam));
        $('.c-header__search__results').css('display', 'flex');
        $('.c-header__search__all-results-button').attr("href", `/documents?search[q]=${queryParam}`);
        $('.c-header__search__all-results-button').show();
      } else {
        $('.c-header__search__results').css('display', 'flex');
        productResults.html("<div id='search-results'></div><span class='c-header__search__no-result link'>Nincs találat a megadott kifejezésre.</span>");
        $('.c-header__search__all-results-button').hide();
      }
    };
  }

  searchField.keyup(ev => {
    if (ev.keyCode == 27) { 
      $('.c-header__search').val('');
      $('.c-header__search__results').css('display', 'none');
      $('.c-header__search__all-results-button').hide();
    } else if (ev.keyCode == 13) { 
      let url = $('a.c-header__search__all-results-button').attr('href');
      window.location.href = url;
    } else {
      if(window.ajax) {
        window.ajax.abort();
        console.log('ajax rejected');
      }
      if(window.searchCall) {
        clearTimeout(window.searchCall);
      }
  
      window.searchCall = setTimeout(function(){ 
        makeSearchCall(); 
      }, 400);
    }
  });
});